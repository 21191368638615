import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Checkbox, Input, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Grid from "../../Components/Grid";
import AddEdit from "./AddEdit.component";
import SeasonModel from "../../Models/season.model";
import moment from "moment";
import { agencyAtom } from "../../Atoms/agency.atom";
import { seasonAtoms } from "../../Atoms/seasons.atom";
import { createAPIEndpoint } from "../../Api/authenticated.requests";
import { ENDPOINTS } from "../../Api/enpoints";
export default function Seasons(props) {
  // STATE
  const [data, setdata] = useRecoilState(seasonAtoms);
  const [totalCount, settotalCount] = useState(0);
  const [state, setstate] = useRecoilState(exportAddAtom);

  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useState(new SeasonModel());
  const agency = useRecoilValue(agencyAtom);
  // ATOMS
  // HELPERS
  const reset = () => {
    setmodel(new SeasonModel());
    setError("");
  };
  // API CALLS
  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.Season, { page: 1, take: 1000 })
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
      })
      .catch((e) => setError(e.Message));
  };
  const save = () => {
    if (model.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Season)
        .update(model.id, model)
        .then((res) => {
          fetch();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
        });
    } else {
      APi.createAPIEndpoint(APi.ENDPOINTS.Season)
        .create({ ...model, agencyId: agency.id })
        .then((res) => {
          fetch();
          reset();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
        });
    }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Season)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  const getBYId = (id) => {
    setError("");

    setmodel(data.find((el) => el.id == id));
  };

  // LIFE CYCLES
  useEffect(() => {
    fetch();
  }, []);
  const columns = [
    {
      value: "designation",

      name: "Designation",
      render: (v) => <b>{v}</b>,
    },

    {
      value: "dateBegin",
      name: "Begin Date",
      render: (v) => <b style={{ color: "green" }}>{moment(v).format("L")}</b>,
    },

    {
      value: "dateEnd",
      name: "End Date",
      render: (v) => <b style={{ color: "green" }}>{moment(v).format("L")}</b>,
    },
    {
      value: "isCurrent",
      value2: "id",
      name: "Is Current",
      render: (v, id) => (
        <Checkbox
          checked={v}
          onClick={(e) => {
            createAPIEndpoint(ENDPOINTS.Season + "/makeCurrent/" + id)
              .customGet()
              .then((res) => {
                fetch();
              });
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <ExportAdd
        noExport
        size="md"
        save={save}
        ActionOnClose={reset}
        AddComponent={
          <AddEdit error={error} model={model} _setmodel={setmodel} />
        }
      />
      <Grid
        editAction={(id) => {
          getBYId(id);
          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
    </div>
  );
}
