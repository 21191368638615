import { Dashboard } from "@rsuite/icons";
import CreditCardMinusIcon from "@rsuite/icons/CreditCardMinus";
import CreditCardPlusIcon from "@rsuite/icons/CreditCardPlus";
import GearIcon from "@rsuite/icons/Gear";
import PageIcon from "@rsuite/icons/Page";
import PageNextIcon from "@rsuite/icons/PageNext";
import ProjectIcon from "@rsuite/icons/Project";

import PagePreviousIcon from "@rsuite/icons/PagePrevious";
import PeopleBranchIcon from "@rsuite/icons/PeopleBranch";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiTrip } from "react-icons/bi";
import { BsMenuButton } from "react-icons/bs";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  Container,
  Content,
  Dropdown,
  Header,
  Nav,
  Navbar,
  Sidebar,
  Sidenav,
} from "rsuite";
import "../node_modules/react-vis/dist/style.css";
import { APi } from "./Api";
import "./App.scss";
import { agencyAtoms } from "./Atoms/agencies.atom";
import { agencyAtom } from "./Atoms/agency.atom";
import { AgentsAtom } from "./Atoms/agents.atom";
import { Airlines } from "./Atoms/airlines.atom";
import { isLogged } from "./Atoms/auth.atom";
import { ConfigState } from "./Atoms/config.atom";
import { CurrencyAtom } from "./Atoms/currency.atom";
import { DefaultTemplateUmrah } from "./Atoms/defaultuMrahPack";
import { ExchangeAtom } from "./Atoms/exchange.atom";
import { groupsAtoms } from "./Atoms/groups.atoms";
import { recoveryPoints } from "./Atoms/recoveryPoints.atom";
import Configs from "./Configs";
import AccountsFollowUp from "./Screens/AccountFollowUp";
import Login from "./Screens/Auth/login";
import Check from "./Screens/Check";
import ClientGuarantee from "./Screens/ClientGuarantee";
import Home from "./Screens/Dashboard";
import Expense from "./Screens/Expense";
import Forcast from "./Screens/Forcast";
import ForcastVo from "./Screens/ForcastVo";
import Groups from "./Screens/Group";
import Invoice from "./Screens/Invoice";
import SettingInvoice from "./Screens/Invoice/settings";
import AccountsPayableLedger from "./Screens/Ledger/AccountsPayableLedger";
import AccountsReceivableLedger from "./Screens/Ledger/AccountsReceivableLedger";
import PackagesUmrah from "./Screens/PackageUmrah";
import DefaultPack from "./Screens/PackageUmrah/DefaultPack";
import PricesView from "./Screens/PackageUmrah/PricesView";
import Packages from "./Screens/Packages";
import Payment from "./Screens/Payment";
import PaymentProvider from "./Screens/PaymentProvider";
import Purchases from "./Screens/Purchases";
import Rawdhas from "./Screens/Rawdha";
import Rooming from "./Screens/Rooming";
import Rooming2 from "./Screens/Rooming/rooming2";
import Sale from "./Screens/Sale";
import SaleBus from "./Screens/SaleBus";
import SaleHosting from "./Screens/SaleHosting";
import SaleUmrah from "./Screens/SaleUmrah";
import SaleUmrahConsult from "./Screens/SaleUmrahConsult";
import SaleVo from "./Screens/SaleVo";
import SendToWakil from "./Screens/SendToWakil";
import AgentBookingFlight from "./Screens/Settings/AgentBookingFlight";
import Currencies from "./Screens/Settings/Currencies";
import Employee from "./Screens/Settings/Employee";
import EmployeePosition from "./Screens/Settings/EmployeePosition";
import Exchanges from "./Screens/Settings/Exchange";
import Preference from "./Screens/Settings/Preferences";
import ServiceTypes from "./Screens/Settings/ServiceType";
import Tags from "./Screens/Settings/Tags";
import Ticketing from "./Screens/Ticketing";
import CCA from "./Screens/Tiers/CCA";
import Client from "./Screens/Tiers/Client";
import Creditor from "./Screens/Tiers/Creditor";
import Providor from "./Screens/Tiers/Provider";
import Bank from "./Screens/Treasory/Bank";
import Checkout from "./Screens/Treasory/Checkout";
import RecoveryPoints from "./Screens/Treasory/RecoveryPoint";
import Users from "./Screens/Users";
import VolStatistics from "./Screens/VolStatistics";
import Agencies from "./Screens/agencies";
import OperationsRawdha from "./Screens/operationsRawdha";
import UploadVisas from "./Screens/uploadVisas/UploadVisas";
import Seasons from "./Screens/Season";

import { seasonAtoms } from "./Atoms/seasons.atom";
const iconStyles = {
  width: 56,
  height: 56,
  padding: 18,
  lineHeight: "56px",
  textAlign: "center",
};

const App = (props) => {
  const [conf, setconf] = useRecoilState(ConfigState);

  const [agencies, setagencies] = useRecoilState(agencyAtoms);
  const settemplate = useSetRecoilState(DefaultTemplateUmrah);
  const [expand, setExpand] = useState(false);
  const [loaded, setloaded] = useState(false);
  const [active, setactive] = useState(1);
  const [logged, setlogged] = useRecoilState(isLogged);
  const setcurrencies = useSetRecoilState(CurrencyAtom);
  const setexchanges = useSetRecoilState(ExchangeAtom);
  const setairlines = useSetRecoilState(Airlines);
  const setrecoveryPoints = useSetRecoilState(recoveryPoints);
  const setAgents = useSetRecoilState(AgentsAtom);
  const setAgency = useSetRecoilState(agencyAtom);
  const setSeasons = useSetRecoilState(seasonAtoms);

  const [pageTitle, setpageTitle] = useState("Dashboard");
  const location = useLocation();
  const fetchAgencies = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Agency + "/getAll", {})
      .fetchAll()
      .then((res) => {
        setagencies(res.data);
      })
      .catch((e) => console.log(e.Message));
  };
  const [data2, setdata2] = useRecoilState(groupsAtoms);

  useEffect(() => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Group + "/autocomplete", { q: "" })
      .fetchAll()
      .then((res) => {
        setdata2(res.data);
      })
      .catch((e) => {});
    fetchAgencies();
    APi.createAPIEndpoint(APi.ENDPOINTS.AgentBookingFlight, {
      page: 1,
      take: 1000,
    })
      .fetchAll()
      .then((res) => setAgents(res.data.data));
    APi.createAPIEndpoint(APi.ENDPOINTS.Currency, { page: 1, take: 1000 })
      .fetchAll()
      .then((res) => setcurrencies(res.data.data));
    APi.createAPIEndpoint(APi.ENDPOINTS.Airline, { page: 1, take: 10000 })
      .fetchAll()
      .then((res) => setairlines(res.data));
    APi.createAPIEndpoint(
      APi.ENDPOINTS.RecoveryPoint,
      { q: "" },
      "/autocomplete"
    )
      .customGet()
      .then((res) =>
        setrecoveryPoints(
          res.data.map((el) => {
            return { value: el.id, label: el.name };
          })
        )
      );
    APi.createAPIEndpoint(APi.ENDPOINTS.Exchange, { page: 1, take: 1000 })
      .fetchAll()
      .then((res) => setexchanges(res.data));
    APi.createAPIEndpoint(APi.ENDPOINTS.Season, { page: 1, take: 1000 })
      .fetchAll()
      .then((res) => {
        console.log(res.data.data);
        setSeasons(res.data.data);
      })
      .catch((e) => console.log(e.Message));
    let log = true;
    if (
      !localStorage.getItem("auth") ||
      !JSON.parse(localStorage.getItem("auth")).token
    ) {
      log = false;
    } else {
    }
    setlogged(log);
    setTimeout(() => {
      setloaded(true);
      setpageTitle(routes[location.pathname]);
    }, 1000);
  }, [logged]);
  useEffect(() => {
    setExpand(false);
  }, [pageTitle]);

  const AuthGard = (Screen) => (logged ? Screen : <Login />);
  useEffect(() => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Config)
      .fetchAll()
      .then((res) => setconf(res.data.length ? res.data[0] : {}));
  }, []);

  return (
    <div className="app">
      <Container>
        {logged && (
          <Sidebar className={"app-sidebar " + (expand ? "show" : "")}>
            <Sidenav.Header>
              <div className="app-sidebar-header">
                <div>
                  <b style={{ marginLeft: 12, fontSize: "large" }}>
                    UMRAH MANAGEMENT{" "}
                  </b>
                </div>
                <button
                  className="close_menu_btn"
                  onClick={(e) => setExpand((prev) => !prev)}
                >
                  x
                </button>
              </div>
            </Sidenav.Header>
            <Sidenav defaultOpenKeys={["1"]} appearance="subtle">
              <Sidenav.Body>
                <Nav>
                  <Nav.Item
                    eventKey="1"
                    onClick={() => {
                      setactive("1");
                      setpageTitle("Dashboard");
                    }}
                    active={active == "1"}
                    icon={
                      <span className="side-span">
                        <Dashboard />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/">
                      Dashboard
                    </Link>
                  </Nav.Item>
                  <hr></hr>
                  {/* <Nav.Item
                    onClick={() => {
                      setactive("2");
                      setpageTitle("Ventes");
                    }}
                    active={active == "2"}
                    eventKey="2"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/sales">
                      Ventes Divers
                    </Link>
                  </Nav.Item> 
                  <hr></hr>
                   <Nav.Item
                    onClick={() => {
                      setactive("25a");
                      setpageTitle("Ventes");
                    }}
                    active={active == "25a"}
                    eventKey="25a"
                    icon={
                      <span className="side-span">
                        <IoAirplaneOutline />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/ticketing">
                      Ventes Billetterie
                    </Link>
                  </Nav.Item> 
                  <hr></hr>
                  <Nav.Item
                    onClick={() => {
                      setactive("25z");
                      setpageTitle("Ventes");
                    }}
                    active={active == "25z"}
                    eventKey="25z"
                    icon={
                      <span className="side-span">
                        <RiHotelLine /> 
                       </span>
                    }
                  >
                    <Link className={"side_link "} to="/hosting">
                      Ventes Hôtellerie
                    </Link>
                  </Nav.Item>
                  <hr></hr>
                  <Nav.Item
                    onClick={() => {
                      setactive("25b");
                      setpageTitle("Ventes Autobus");
                    }}
                    active={active == "25b"}
                    eventKey="25b"
                    icon={
                      <span className="side-span">
                         <FaBus /> 
                        <BiTrip></BiTrip>
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/sales_bus">
                      Ventes Autobus
                    </Link>
                  </Nav.Item>
                  <hr></hr>*/}
                  {/* <Dropdown
                    eventKey="9-0"
                    trigger="hover"
                    title="OMRAH"
                    icon={
                      <span className="side-span">
                        <BiTrip />
                      </span>
                    }
                    placement="rightStart"
                  > */}
                  <Nav.Item
                    icon={
                      <span className="side-span">
                        <BiTrip></BiTrip>
                      </span>
                    }
                    eventKey="9-59999"
                    onClick={() => {
                      setactive("9-59999");
                      setpageTitle("Template Omrah");
                    }}
                    active={active == "9-59999"}
                  >
                    <Link className={"side_link "} to="/template_umrah">
                      Template Omrah
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    icon={
                      <span className="side-span">
                        <BiTrip></BiTrip>
                      </span>
                    }
                    eventKey="9-5"
                    onClick={() => {
                      setactive("9-5");
                      setpageTitle("Packs Omrah");
                    }}
                    active={active == "9-5"}
                  >
                    <Link className={"side_link "} to="/packages_umrah">
                      Packs Omrah
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("2222");
                      setpageTitle("Ventes Umrah");
                    }}
                    active={active == "2222"}
                    eventKey="2222"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/sales_umrah">
                      Ventes Umrah
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("Consultation Ventes Umrah");
                      setpageTitle("Consultation Ventes Umrah");
                    }}
                    active={active == "Consultation Ventes Umrah"}
                    eventKey="Consultation Ventes Umrah"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/consult_sales_umrah">
                      Consultation Ventes Umrah
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    icon={
                      <span className="side-span">
                        <BiTrip></BiTrip>
                      </span>
                    }
                    eventKey="9-66"
                    onClick={() => {
                      setactive("9-66");
                      setpageTitle("Prix Omrah");
                    }}
                    active={active == "9-66"}
                  >
                    <Link className={"side_link "} to="/prices_umrah">
                      Prix Omrah
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("2228882");
                      setpageTitle("Prévisions");
                    }}
                    active={active == "2228882"}
                    eventKey="2228882"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/forcast">
                      Prévisions
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("rooming");
                      setpageTitle("Rooming");
                    }}
                    active={active == "rooming"}
                    eventKey="rooming"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/rooming">
                      Rooming
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("rooming2");
                      setpageTitle("Rooming 2");
                    }}
                    active={active == "rooming2"}
                    eventKey="rooming2"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/rooming2">
                      Rooming 2
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("vol_statistics");
                      setpageTitle("Vols Statistiques");
                    }}
                    active={active == "vol_statistics"}
                    eventKey="vol_statistics"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/vol_statistics">
                      Vols Statistique
                    </Link>
                  </Nav.Item>
                  <hr></hr>
                  <Nav.Item
                    onClick={() => {
                      setactive("groups");
                      setpageTitle("Groupes");
                    }}
                    active={active == "groups"}
                    eventKey="groups"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/groups">
                      Groupes
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("send_to_wakil");
                      setpageTitle("Opertaions");
                    }}
                    active={active == "send_to_wakil"}
                    eventKey="send_to_wakil"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/send_to_wakil">
                      Operations
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("upload_visas");
                      setpageTitle("Upload Visas");
                    }}
                    active={active == "upload_visas"}
                    eventKey="upload_visas"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/upload_visas">
                      Upload Visas
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("configs");
                      setpageTitle("Config Wakil");
                    }}
                    active={active == "configs"}
                    eventKey="configs"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/configs">
                      Config Wakil
                    </Link>
                  </Nav.Item>
                  <hr></hr>
                  <Nav.Item
                    onClick={() => {
                      setactive("rawdhas");
                      setpageTitle("Rawdha");
                    }}
                    active={active == "rawdhas"}
                    eventKey="rawdhas"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/rawdhas">
                      Rawdha
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("rawdhas_operations");
                      setpageTitle("Operations Rawdha");
                    }}
                    active={active == "rawdhas_operations"}
                    eventKey="rawdhas_operations"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/rawdhas_operations">
                      Operations Rawdha
                    </Link>
                  </Nav.Item>
                  <hr></hr>
                  <Nav.Item
                    icon={
                      <span className="side-span">
                        <BiTrip></BiTrip>
                      </span>
                    }
                    eventKey="9-51"
                    onClick={() => {
                      setactive("9-51");
                      setpageTitle("Voyages Organisés");
                    }}
                    active={active == "9-51"}
                  >
                    <Link className={"side_link "} to="/packages">
                      Voyages Organisés
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("22");
                      setpageTitle("Ventes Voyages Organisées");
                    }}
                    active={active == "22"}
                    eventKey="22"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/sales_vo">
                      Ventes VOs
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("22288825");
                      setpageTitle("Prévisions VOs");
                    }}
                    active={active == "22288825"}
                    eventKey="22288825"
                    icon={
                      <span className="side-span">
                        <CreditCardPlusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/forcastvo">
                      Prévisions VOs
                    </Link>
                  </Nav.Item>
                  <hr></hr>
                  <Nav.Item
                    eventKey="3"
                    onClick={() => {
                      setactive("3");
                      setpageTitle("Dépenses");
                    }}
                    active={active == "3"}
                    icon={
                      <span className="side-span">
                        <CreditCardMinusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/expense">
                      Dépenses
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("9");
                      setpageTitle("Achats");
                    }}
                    active={active == "9"}
                    eventKey="9"
                    icon={
                      <span className="side-span">
                        <CreditCardMinusIcon />
                      </span>
                    }
                  >
                    <Link className={"side_link "} to="/purchases">
                      Achats
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    eventKey="13"
                    icon={
                      <span className="side-span">
                        <PageIcon />
                      </span>
                    }
                    onClick={() => {
                      setactive("13");
                      setpageTitle("Paiements Fournisseurs");
                    }}
                    active={active == "13"}
                  >
                    <Link className={"side_link "} to="/payments_providers">
                      Paiements Fournisseurs
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("12");
                      setpageTitle("Grand Livre Fournisseurs");
                    }}
                    active={active == "12"}
                    eventKey="12"
                    icon={
                      <span className="side-span">
                        <CreditCardMinusIcon />
                      </span>
                    }
                  >
                    <Link
                      className={"side_link "}
                      to="/accounts_receivable_ledger"
                    >
                      Grand Livre Fournisseurs
                    </Link>
                  </Nav.Item>
                  <hr></hr>
                  <Nav.Item
                    eventKey="5"
                    icon={
                      <span className="side-span">
                        <PageIcon />
                      </span>
                    }
                    onClick={() => {
                      setactive("5");
                      setpageTitle(" Gestion des chéques");
                    }}
                    active={active == "5"}
                  >
                    <Link className={"side_link "} to="/checks">
                      Gestion des chèques
                    </Link>
                  </Nav.Item>
                  <hr></hr>
                  <Nav.Item
                    eventKey="10"
                    icon={
                      <span className="side-span">
                        <PageIcon />
                      </span>
                    }
                    onClick={() => {
                      setactive("10");
                      setpageTitle(" Gestion des recouvrements");
                    }}
                    active={active == "10"}
                  >
                    <Link className={"side_link "} to="/payments">
                      Gestion des recouvrements
                    </Link>
                  </Nav.Item>

                  <Nav.Item
                    eventKey="14"
                    icon={
                      <span className="side-span">
                        <PageIcon />
                      </span>
                    }
                    onClick={() => {
                      setactive("14");
                      setpageTitle("Garanties Clients");
                    }}
                    active={active == "14"}
                  >
                    <Link className={"side_link "} to="/guarantee_clients">
                      Garanties Clients
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    onClick={() => {
                      setactive("11");
                      setpageTitle("Grand Livre Clients");
                    }}
                    active={active == "11"}
                    eventKey="11"
                    icon={
                      <span className="side-span">
                        <CreditCardMinusIcon></CreditCardMinusIcon>
                      </span>
                    }
                  >
                    <Link
                      className={"side_link "}
                      to="/accounts_payable_ledger"
                    >
                      Grand Livre Clients
                    </Link>
                  </Nav.Item>
                  <Nav.Item
                    eventKey="15"
                    icon={
                      <span className="side-span">
                        <PageIcon />
                      </span>
                    }
                    onClick={() => {
                      setactive("15");
                      setpageTitle("Suivie Clients");
                    }}
                    active={active == "15"}
                  >
                    <Link className={"side_link "} to="/follow_up_clients">
                      Suivie Clients
                    </Link>
                  </Nav.Item>
                  <hr></hr>
                  <Dropdown
                    eventKey="4"
                    trigger="hover"
                    title="Tiers"
                    icon={
                      <span className="side-span">
                        <PeopleBranchIcon />
                      </span>
                    }
                    placement="rightStart"
                  >
                    {/* <Dropdown.Item
                      eventKey="4-4"
                      onClick={() => {
                        setactive("4-4");
                        setpageTitle("Comptes courants d'associés");
                      }}
                      active={active == "4-4"}
                    >
                      <Link className={"side_link "} to="/tiers/cca">
                        Comptes courants d'associés
                      </Link>
                    </Dropdown.Item> */}
                    <Dropdown.Item
                      eventKey="4-1"
                      onClick={() => {
                        setactive("4-1");
                        setpageTitle(" Clients");
                      }}
                      active={active == "4-1"}
                    >
                      <Link className={"side_link "} to="/tiers/clients">
                        Clients
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="4-2"
                      onClick={() => {
                        setactive("4-2");
                        setpageTitle(" Fournisseurs");
                      }}
                      active={active == "4-2"}
                    >
                      <Link className={"side_link "} to="/tiers/providers">
                        Fournisseurs
                      </Link>
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      eventKey="4-3"
                      onClick={() => {
                        setactive("4-3");
                        setpageTitle(" Créditeurs");
                      }}
                      active={active == "4-3"}
                    >
                      <Link className={"side_link "} to="/tiers/creditors">
                        Créditeurs
                      </Link>
                    </Dropdown.Item> */}
                  </Dropdown>
                  <Dropdown
                    eventKey="6"
                    trigger="hover"
                    title="Trésorerie"
                    icon={
                      <span className="side-span">
                        <ProjectIcon />
                      </span>
                    }
                    placement="rightStart"
                  >
                    <Dropdown.Item
                      eventKey="6-1"
                      onClick={() => {
                        setactive("6-1");
                        setpageTitle("Banques");
                      }}
                      active={active == "6-1"}
                    >
                      <Link className={"side_link "} to="/treasory/banks">
                        Banques
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="6-2"
                      onClick={() => {
                        setactive("6-2");
                        setpageTitle(" Caisses");
                      }}
                      active={active == "6-2"}
                    >
                      <Link className={"side_link "} to="/treasory/checkouts">
                        Caisses
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="6-3"
                      onClick={() => {
                        setactive("6-3");
                        setpageTitle("Points De Recouvrements");
                      }}
                      active={active == "6-3"}
                    >
                      <Link
                        className={"side_link "}
                        to="/treasory/recovery_points"
                      >
                        Points De Recouvrements
                      </Link>
                    </Dropdown.Item>
                  </Dropdown>
                  {/* <Dropdown
                    eventKey="7"
                    trigger="hover"
                    title="Gestion des factures"
                    icon={<DocPassIcon />}
                    placement="rightStart"
                  >
                    <Dropdown.Item
                      eventKey="7-1"
                      onClick={() => {
                        setactive("7-1");
                        setpageTitle("Factures");
                      }}
                      active={active == "7-1"}
                    >
                      <Link className={"side_link "} to="/invoices">
                        Factures
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="7-2"
                      onClick={() => {
                        setactive("7-2");
                        setpageTitle("Ventes");
                      }}
                      active={active == "7-2"}
                    >
                      
                      <Link className={"side_link "} to="/invoices/settings">
                        Paramétrage Facture
                      </Link>
                    </Dropdown.Item>
                  </Dropdown> */}
                  <Dropdown
                    eventKey="8"
                    trigger="hover"
                    title="Paramétrage"
                    icon={
                      <span className="side-span">
                        <GearIcon />
                      </span>
                    }
                    placement="rightStart"
                  >
                    {" "}
                    <Dropdown.Item
                      eventKey="55-1"
                      onClick={() => {
                        setactive("55-1");
                        setpageTitle(" Seasons");
                      }}
                      icon={
                        <span className="side-span">
                          <Dashboard />
                        </span>
                      }
                      active={active == "55-1"}
                    >
                      <Link className={"side_link "} to="/seasons">
                        Seasons
                      </Link>
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      eventKey="8-1"
                      onClick={() => {
                        setactive("8-1");
                        setpageTitle("Types Des Services");
                      }}
                      active={active == "8-1"}
                    >
                      <Link className={"side_link "} to="/settings/services">
                        
                        Types Des Services
                      </Link>
                    </Dropdown.Item> */}
                    <Dropdown.Item
                      eventKey="8-1"
                      onClick={() => {
                        setactive("8-1");
                        setpageTitle("Agents de Billéterie");
                      }}
                      active={active == "8-1"}
                    >
                      <Link
                        className={"side_link "}
                        to="/settings/flight_booking_agents"
                      >
                        Agents de Billéterie
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="8-2"
                      onClick={() => {
                        setactive("8-2");
                        setpageTitle("Devises");
                      }}
                      active={active == "8-2"}
                    >
                      <Link className={"side_link "} to="/settings/currencies">
                        Devises
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="8-3"
                      onClick={() => {
                        setactive("8-3");
                        setpageTitle("Postes des employés");
                      }}
                      active={active == "8-3"}
                    >
                      <Link
                        className={"side_link "}
                        to="/settings/employee_position"
                      >
                        Postes des employés
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="Liste des employés"
                      onClick={() => {
                        setactive("Liste des employés");
                        setpageTitle("Liste des employés");
                      }}
                      active={active == "Liste des employés"}
                    >
                      <Link className={"side_link "} to="/settings/employee">
                        Liste des employés
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="8-4"
                      onClick={() => {
                        setactive("8-4");
                        setpageTitle("Tags");
                      }}
                      active={active == "8-4"}
                    >
                      <Link className={"side_link "} to="/settings/tags">
                        Tags
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="8-5"
                      onClick={() => {
                        setactive("8-5");
                        setpageTitle("Préférences");
                      }}
                      active={active == "8-5"}
                    >
                      <Link className={"side_link "} to="/settings/preference">
                        Préférences
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="9-6"
                      onClick={() => {
                        setactive("9-6");
                        setpageTitle("Echanges");
                      }}
                      active={active == "9-6"}
                    >
                      <Link className={"side_link "} to="/settings/exchanges">
                        Echanges
                      </Link>
                    </Dropdown.Item>
                  </Dropdown>
                  <Nav.Item
                    eventKey="55-1"
                    onClick={() => {
                      setactive("55-1");
                      setpageTitle(" Utilisateurs");
                    }}
                    icon={
                      <span className="side-span">
                        <Dashboard />
                      </span>
                    }
                    active={active == "55-1"}
                  >
                    <Link className={"side_link "} to="/users">
                      Utilisateurs
                    </Link>
                  </Nav.Item>
                  {/* <Nav.Item
                    eventKey="1a2"
                    onClick={() => {
                      setactive("1a2");
                      setpageTitle("Agences");
                    }}
                    active={active == "1a2"}
                  >
                    <Link className={"side_link "} to="/agencies">
                      Agences
                    </Link>
                  </Nav.Item> */}
                </Nav>
              </Sidenav.Body>
            </Sidenav>
            {/* <NavToggle expand={expand} onChange={() => setExpand(!expand)} /> */}
          </Sidebar>
        )}

        <Container className={"hole-container"}>
          {logged && (
            <Header className="page-header">
              <h4>{pageTitle}</h4>

              <div
                style={{
                  display: "inline-flex",
                }}
              >
                <button
                  className="menu_btn"
                  onClick={(e) => setExpand((prev) => !prev)}
                >
                  <BsMenuButton />
                </button>
                <Nav>
                  <Dropdown
                    placement="bottomEnd"
                    trigger="click"
                    icon={<GearIcon size="3em" />}
                    renderTitle={(children) => {
                      return <GearIcon style={iconStyles} />;
                    }}
                  >
                    {/* <Dropdown.Item>Help</Dropdown.Item>
                    <Dropdown.Item>Settings</Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() => {
                        localStorage.removeItem("auth");
                        setlogged(false);
                      }}
                    >
                      Sign out
                    </Dropdown.Item>
                  </Dropdown>
                </Nav>
              </div>
            </Header>
          )}

          <Content className={"app-content " + (!logged ? "" : "logged")}>
            <Switch>
              <Route
                path="/sales"
                render={(props) => AuthGard(<Sale {...props} />)}
              />
              <Route
                path="/hosting"
                render={(props) => AuthGard(<SaleHosting {...props} />)}
              />
              <Route
                path="/sales_vo"
                render={(props) => AuthGard(<SaleVo {...props} />)}
              />
              <Route
                path="/sales_bus"
                render={(props) => AuthGard(<SaleBus {...props} />)}
              />
              <Route
                path="/agencies"
                render={(props) => AuthGard(<Agencies {...props} />)}
              />
              <Route
                path="/sales_umrah"
                render={(props) => AuthGard(<SaleUmrah {...props} />)}
              />
              <Route
                path="/consult_sales_umrah"
                render={(props) => AuthGard(<SaleUmrahConsult {...props} />)}
              />
              <Route
                path="/expense"
                render={(props) => AuthGard(<Expense {...props} />)}
              />
              <Route
                path="/users"
                render={(props) => AuthGard(<Users {...props} />)}
              />
              <Route
                path="/groups"
                render={(props) => AuthGard(<Groups {...props} />)}
              />
              <Route
                path="/upload_visas"
                render={(props) => AuthGard(<UploadVisas {...props} />)}
              />
              <Route
                path="/rawdhas"
                render={(props) => AuthGard(<Rawdhas {...props} />)}
              />
              <Route
                path="/payments"
                render={(props) => AuthGard(<Payment {...props} />)}
              />
              <Route
                path="/payments_providers"
                render={(props) => AuthGard(<PaymentProvider {...props} />)}
              />
              <Route
                path="/rawdhas_operations"
                render={(props) => AuthGard(<OperationsRawdha {...props} />)}
              />
              <Route
                path="/guarantee_clients"
                render={(props) => AuthGard(<ClientGuarantee {...props} />)}
              />
              <Route
                path="/purchases"
                render={(props) => AuthGard(<Purchases {...props} />)}
              />
              <Route
                path="/tiers/creditors"
                render={(props) => AuthGard(<Creditor {...props} />)}
              />
              <Route
                path="/tiers/providers"
                render={(props) => AuthGard(<Providor {...props} />)}
              />
              <Route
                path="/rooming"
                render={(props) => AuthGard(<Rooming {...props} />)}
              />{" "}
              <Route
                path="/rooming2"
                render={(props) => AuthGard(<Rooming2 {...props} />)}
              />
              <Route
                path="/follow_up_clients"
                render={(props) => AuthGard(<AccountsFollowUp {...props} />)}
              />
              <Route
                path="/accounts_payable_ledger"
                render={(props) =>
                  AuthGard(<AccountsPayableLedger {...props} />)
                }
              />
              <Route
                path="/vol_statistics"
                render={(props) => AuthGard(<VolStatistics {...props} />)}
              />
              <Route
                path="/send_to_wakil"
                render={(props) => AuthGard(<SendToWakil {...props} />)}
              />
              <Route
                path="/accounts_receivable_ledger"
                render={(props) =>
                  AuthGard(<AccountsReceivableLedger {...props} />)
                }
              />
              <Route
                path="/tiers/cca"
                render={(props) => AuthGard(<CCA {...props} />)}
              />
              <Route
                path="/tiers/clients"
                render={(props) => AuthGard(<Client {...props} />)}
              />
              <Route
                path="/checks"
                render={(props) => AuthGard(<Check {...props} />)}
              />{" "}
              <Route
                path="/seasons"
                render={(props) => AuthGard(<Seasons {...props} />)}
              />
              <Route
                path="/settings/employee_position"
                render={(props) => AuthGard(<EmployeePosition {...props} />)}
              />
              <Route
                path="/settings/employee"
                render={(props) => AuthGard(<Employee {...props} />)}
              />
              <Route
                path="/settings/services"
                render={(props) => AuthGard(<ServiceTypes {...props} />)}
              />
              <Route
                path="/settings/preference"
                render={(props) => AuthGard(<Preference {...props} />)}
              />
              <Route
                path="/settings/currencies"
                render={(props) => AuthGard(<Currencies {...props} />)}
              />
              <Route
                path="/settings/flight_booking_agents"
                render={(props) => AuthGard(<AgentBookingFlight {...props} />)}
              />
              <Route
                path="/settings/tags"
                render={(props) => AuthGard(<Tags {...props} />)}
              />
              <Route
                path="/treasory/banks"
                render={(props) => AuthGard(<Bank {...props} />)}
              />
              <Route
                path="/treasory/checkouts"
                render={(props) => AuthGard(<Checkout {...props} />)}
              />
              <Route
                path="/treasory/recovery_points"
                render={(props) => AuthGard(<RecoveryPoints {...props} />)}
              />
              <Route
                path="/invoices"
                render={(props) => AuthGard(<Invoice {...props} />)}
              />
              <Route
                path="/invoices/settings"
                render={(props) => AuthGard(<SettingInvoice {...props} />)}
              />
              <Route
                path="/settings/exchanges"
                render={(props) => AuthGard(<Exchanges {...props} />)}
              />
              <Route
                path="/packages"
                render={(props) => AuthGard(<Packages {...props} />)}
              />
              <Route
                path="/ticketing"
                render={(props) => AuthGard(<Ticketing {...props} />)}
              />
              <Route
                path="/packages_umrah"
                render={(props) => AuthGard(<PackagesUmrah {...props} />)}
              />
              <Route
                path="/prices_umrah"
                render={(props) => AuthGard(<PricesView {...props} />)}
              />
              <Route
                path="/forcast"
                render={(props) => AuthGard(<Forcast {...props} />)}
              />
              <Route
                path="/forcastvo"
                render={(props) => AuthGard(<ForcastVo {...props} />)}
              />
              <Route
                path="/template_umrah"
                render={(props) => AuthGard(<DefaultPack {...props} />)}
              />
              <Route
                path="/configs"
                render={(props) => AuthGard(<Configs {...props} />)}
              />
              <Route
                path="/*"
                render={(props) => AuthGard(<Home {...props} />)}
              />
            </Switch>
          </Content>
        </Container>
      </Container>
    </div>
  );
};
const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="subtle" className="nav-toggle">
      <Nav pullRight>
        <Nav.Item onClick={onChange} style={{ width: 56, textAlign: "center" }}>
          {expand ? <PagePreviousIcon /> : <PageNextIcon />}
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};
export default App;

const routes = {
  "/sales": "Ventes",
  "/expense": "Dépenses",
  "/tiers/creditors": "Créditeurs",
  "/tiers/providers": "Fournisseurs",
  "/tiers/clients": "Clients",
  "/checks": " Gestion des chéques",
  "/settings/employee_position": " Postes des employés",
  "/settings/services": " Types Des Services",
  "/settings/currencies": "Devises",
  "/treasory/banks": "Banques",
  "/treasory/checkouts": "Caisses",
  "/invoices": "Factures",
  "/invoices/settings": "Paramétrage Facture",
};
