import { useState } from "react";
import Filter from "../../Components/Common/Filter";
import Responsive from "../../Components/Responsive";
import { Checkbox, Input, SelectPicker, Tag } from "rsuite";
import Prices from "./Prices";
import { roomTypes } from "../../Constants/types";
import format_number from "../../Helpers/number_formatter";
import { useEffect } from "react";
import { APi } from "../../Api";
import { ENDPOINTS } from "../../Api/enpoints";
import { MdOutlineFoodBank } from "react-icons/md";
import { FaHotel } from "react-icons/fa";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { seasonAtoms } from "../../Atoms/seasons.atom";
export default function PricesView() {
  const [packs, setpacks] = useState([]);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    q: "",
    date: null,
    seasonId: 0,
  });
  const [packages, setpackages] = useState([]);
  const seasons = useRecoilValue(seasonAtoms);

  const [vols, setvols] = useState([]);
  const fetchPackages = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getForPrices", { q })
        .customGet()
        .then((res) => {
          setpackages(res.data.data);

          let _vols = res.data.data.reduce(
            (a, b) =>
              a.concat(
                b.disponibilities.map((el) => ({ packageUmrahId: b.id, ...el }))
              ),
            []
          );
          console.log(_vols);
          setvols(_vols.filter((d) => d.onStock));
        });
    }
  };
  const fetch = () => {
    APi.createAPIEndpoint(ENDPOINTS.PackageUmrah + "/getForPrices", filterModel)
      .fetchAll()
      .then((res) => setpacks(res.data.data));
  };
  useEffect(() => {
    fetch();
    fetchPackages();
  }, []);

  return (
    <div>
      <Filter search={fetch}>
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Season: </label>
          <SelectPicker
            data={[{ label: "Tout", value: 0 }].concat(
              seasons.map((c) => {
                return { label: c.designation, value: c.id };
              })
            )}
            block
            noSearch
            value={filterModel.seasonId}
            onSelect={(seasonId) => {
              setfilterModel((prev) => {
                return { ...prev, seasonId };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Nom: </label>
          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Nom Omrah: </label>
          <SelectPicker
            onSearch={fetchPackages}
            data={
              packages
                ? [{ label: "Tout", value: 0 }].concat(
                    packages.map((el) => {
                      return { label: el.name, value: el.id };
                    })
                  )
                : [{ label: "Tout", value: 0 }]
            }
            block
            noSearch
            value={filterModel.packageUmrahId}
            onSelect={(packageUmrahId) => {
              setfilterModel((prev) => {
                return { ...prev, packageUmrahId };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Vol: </label>
          <SelectPicker
            data={[{ label: "Tout", value: 0 }].concat(
              vols
                ? vols.map((el) => {
                    return {
                      label: (
                        <div style={{ color: "#232323" }}>
                          {moment(el.date).format("DD MMM YYYY")} (
                          <i style={{ color: "#999" }}>
                            {packages &&
                              packages.find((l) => l.id == el.packageUmrahId) &&
                              packages.find((l) => l.id == el.packageUmrahId)
                                .name}
                          </i>
                          )
                        </div>
                      ),
                      value: el.id,
                    };
                  })
                : []
            )}
            block
            noSearch
            value={filterModel.disponibilityId}
            onSelect={(disponibilityId) => {
              setfilterModel((prev) => {
                return { ...prev, disponibilityId };
              });
            }}
          />
        </Responsive>
      </Filter>
      <div className="p-10">
        {packs &&
          packs.map((pack) => (
            <div>
              <h4>{pack.name}</h4>
              <Responsive m={5.5} l={5.5} xl={5.5} className="p-10">
                {/* <div
                style={{
                  background: "#fff",
                  padding: "5px",
                  borderRadius: "5px",
                  boxShadow: "-11px 4px 26px -10px rgba(31,50,150,0.67)",
                }}
              >
                
                <table>
                  <thead>
                    <tr>
                      <th>Designation</th>
                      <th>Hôtel Makkah</th>
                      <th>Hôtel Madinah</th>

                      <th>Prix </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pack.prices.map((p, i) => (
                      <tr>
                        <td>
                          {
                            <Tag
                              color={
                                ["red", "green", "yellow"][
                                  roomTypes.findIndex(
                                    (__el) =>
                                      __el.label ==
                                      p.arrangementMadinahDesignation
                                  )
                                ]
                              }
                            >
                              {p.arrangementMadinahDesignation}
                            </Tag>
                          }
                        </td>
                        <td>
                          <span style={{ color: "#eee" }}>
                            <FaHotel />
                          </span>
                          <b> {p.hotelMakkahName}</b>
                        </td>
                        <td>
                          <span style={{ color: "#eee" }}>
                            <FaHotel />
                          </span>
                          <b>{p.hotelMadinahName}</b>
                        </td>

                        <td>
                          <b>{format_number(p.price)}</b>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}
                <PriceOverviewItem pack={pack} />
                {/*  <div
                style={{
                  marginTop: "5px",
                  background: "#fff",
                  padding: "5px",
                  borderRadius: "5px",
                  boxShadow: "-11px 4px 26px -10px rgba(31,50,150,0.67)",
                }}
              >
                {pack.hotels &&
                  pack.hotels.map((h) => {
                    if (h.packageUmrahMealTypes)
                      return h.packageUmrahMealTypes.map((m) => (
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "5px",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            margin: "2px",
                          }}
                        >
                          <b
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "10px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "25px",
                                color: "rgb(70, 103, 209)",
                                borderRadius: "50%",
                                border: "1px solid #eee",
                                width: "40px",
                                height: "40px",
                                textAlign: "center",
                                display: "inline-block",
                                marginRight: "8px",
                                background: "rgb(70, 103, 209,0.2)",
                              }}
                            >
                              <MdOutlineFoodBank />
                            </span>
                            <span>
                              {m.designation}
                              <br></br>
                              <span
                                style={{
                                  textTransform: "uppercase",
                                  color: "#888",
                                  fontWeight: "lighter",
                                }}
                              >
                                {h.name}
                                <span style={{ fontSize: "0.7em" }}>
                                  {h.isMakkah ? "Makkah" : "Madinah"}
                                </span>
                              </span>
                            </span>
                          </b>
                          <b
                            style={{
                              color: "#53af50",
                              fontSize: "18px",
                              margin: "3px",
                            }}
                          >
                            {format_number(h.duration * h.exchange * m.cost)}
                            <span
                              style={{
                                color: "#888",
                                fontSize: "11px",
                                fontWeight: "400",
                              }}
                            >
                              TND
                            </span>
                          </b>
                        </div>
                      ));
                  })}
              </div> */}
              </Responsive>
              <Responsive
                style={{
                  background: "#fff",
                  padding: "5px",
                  borderRadius: "5px",
                  boxShadow: "-11px 4px 26px -10px rgba(31,50,150,0.67)",
                }}
                m={6}
                l={6}
                xl={6}
                className="p-5"
              >
                {pack.disponibilities.map((disp) => (
                  <div
                    style={{
                      background: disp.onStock ? "#fff" : "rgb(244,81,108,0.2)",
                      padding: "3px",
                      borderRadius: "5px",
                      boxShadow: "-14px 4px 26px -15px rgba(31,50,150,0.67)",
                      display: "flex",
                      alignItems: "center",
                      margin: "3px",
                      border: "1px solid #eee",
                    }}
                  >
                    <img
                      alt=""
                      src={disp.airline && disp.airline.logo}
                      style={{ width: "50px" }}
                    />
                    <div style={{ padding: "4px" }}>
                      <h6>{disp.date.substring(0, 10)}</h6>
                      <span
                        style={{ fontWeight: "lighter", fontSize: "small" }}
                      >
                        NOMBRE DES PLACES DISPO. :
                      </span>
                      <b>{disp.onStock}</b>
                    </div>
                  </div>
                ))}
              </Responsive>
              <hr></hr>
            </div>
          ))}
      </div>
      <style jsx>{`
        table {
          border-radius: 5px;
        }
        thead {
           {
            /* background: rgb(70, 103, 209); */
          }
          color: #fff;
          border-radius: 5px;
        }
        th,
        td {
          padding: 5px 8px;
        }
        th {
          text-align: left;
        }
      `}</style>
    </div>
  );
}

export const PriceOverviewItem = ({ pack }) => {
  const [data, setdata] = useState(null);
  const groupped_by = (arr) => {
    arr = arr
      .filter((el) => !el.isHidden)
      .map((el, i) => {
        return {
          ...el,
          h: el.hotelMakkahName + " - " + el.hotelMadinahName,
          index: i,
        };
      });

    let eles = new Set(arr.map((el) => el.h));
    let res = {};
    eles.forEach((el) => {
      res[el] = arr.filter((p) => p.h == el);
    });
    setdata(res);
  };
  useEffect(() => {
    if (pack.prices) {
      groupped_by(pack.prices);
    }
  }, [pack]);
  return (
    <>
      {data &&
        Object.keys(data).map((k, __i) => (
          <div
            style={{
              marginBottom: "30px",
              background: "#fff",
              borderRadius: "5px",

              boxShadow: "-11px 4px 26px -10px rgba(31,50,150,0.67)",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <table>
                <thead
                  style={{
                    background: __i % 2 ? "rgba(236,182,28)" : "rgb(31,50,150)",
                    color: __i % 2 ? "" : "#fff",
                  }}
                >
                  <tr>
                    <th>Designation</th>

                    <th>QUAD </th>
                    <th>TRIPLE </th>
                    <th>DOUBLE </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* <td>
                        {
                          <Tag
                            color={
                              ["red", "green", "yellow"][
                                roomTypes.findIndex(
                                  (__el) =>
                                    __el.label ==
                                    p.arrangementMadinahDesignation
                                )
                              ]
                            }
                          >
                            {p.arrangementMadinahDesignation}
                          </Tag>
                        }
                      </td> */}

                    <td>
                      <span style={{ color: "#eee" }}>
                        <FaHotel />
                      </span>
                      <b> {k}</b>
                    </td>

                    <td>
                      <b style={{ color: "#53af50", fontSize: "17px" }}>
                        {data[k].find((el) =>
                          el.arrangementMadinahDesignation
                            .toUpperCase()
                            .includes("QUAD")
                        )
                          ? format_number(
                              data[k].find((el) =>
                                el.arrangementMadinahDesignation
                                  .toUpperCase()
                                  .includes("QUAD")
                              ).price
                            )
                          : "-"}
                      </b>
                    </td>
                    <td>
                      <b style={{ color: "#53af50", fontSize: "17px" }}>
                        {data[k].find((el) =>
                          el.arrangementMadinahDesignation
                            .toUpperCase()
                            .includes("TRIPLE")
                        )
                          ? format_number(
                              data[k].find((el) =>
                                el.arrangementMadinahDesignation
                                  .toUpperCase()
                                  .includes("TRIPLE")
                              ).price
                            )
                          : "-"}
                      </b>
                    </td>
                    <td>
                      <b style={{ color: "#53af50", fontSize: "17px" }}>
                        {data[k].find((el) =>
                          el.arrangementMadinahDesignation
                            .toUpperCase()
                            .includes("DOUBLE")
                        )
                          ? format_number(
                              data[k].find((el) =>
                                el.arrangementMadinahDesignation
                                  .toUpperCase()
                                  .includes("DOUBLE")
                              ).price
                            )
                          : "-"}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style={{
                marginTop: "5px",
                background: "#fff",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              {pack.hotels &&
                pack.hotels
                  .filter(
                    (h) =>
                      h.name == data[k][0].hotelMadinahName ||
                      h.name == data[k][0].hotelMakkahName
                  )
                  .map((h) => {
                    if (h.packageUmrahMealTypes)
                      return h.packageUmrahMealTypes.map((m) => (
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "5px",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            margin: "2px",
                          }}
                        >
                          <b
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "10px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "25px",
                                color: "rgb(70, 103, 209)",
                                borderRadius: "50%",
                                border: "1px solid #eee",
                                width: "40px",
                                height: "40px",
                                textAlign: "center",
                                display: "inline-block",
                                marginRight: "8px",
                                background: "rgb(70, 103, 209,0.2)",
                              }}
                            >
                              <MdOutlineFoodBank />
                            </span>
                            <span>
                              {m.designation}
                              <br></br>
                              <span
                                style={{
                                  textTransform: "uppercase",
                                  color: "#888",
                                  fontWeight: "lighter",
                                }}
                              >
                                {h.name}
                                <span style={{ fontSize: "0.7em" }}>
                                  {h.isMakkah ? "Makkah" : "Madinah"}
                                </span>
                              </span>
                            </span>
                          </b>
                          <b
                            style={{
                              color: "#53af50",
                              fontSize: "15px",
                              margin: "3px",
                            }}
                          >
                            {m.totalPrice
                              ? format_number(m.totalPrice)
                              : format_number(h.duration * h.exchange * m.cost)}
                            <span
                              style={{
                                color: "#888",
                                fontSize: "11px",
                                fontWeight: "400",
                              }}
                            >
                              TND
                            </span>
                          </b>
                        </div>
                      ));
                  })}
            </div>
          </div>
        ))}
    </>
  );
};
export const PriceOverviewItem2 = ({
  pack,
  model,
  _setmodel,
  multiple = false,
  prices,
  visa = 0,
  ident,
}) => {
  const [visaSelected, setvisaSelected] = useState(null);

  const [data, setdata] = useState(null);
  const [selectedMeal, setselectedMeal] = useState([]);
  const groupped_by = (arr) => {
    arr = arr
      .filter((el) => !el.isHidden)
      .map((el, i) => {
        return {
          ...el,
          h: el.hotelMakkahName + " - " + el.hotelMadinahName,
          index: i,
        };
      });
    let eles = new Set(arr.map((el) => el.h));

    let res = {};
    eles.forEach((el) => {
      res[el] = arr.filter((p) => p.h == el);
    });

    setdata(res);
  };
  useEffect(() => {
    if (prices) groupped_by(prices);
  }, [prices]);
  useEffect(() => {
    let meals = [];
    if (
      pack.hotels &&
      (model.packageUmrahMealTypeMadinahId ||
        model.packageUmrahMealTypeMakkahId)
    ) {
      pack.hotels.map((h) => {
        let _m = h.packageUmrahMealTypes.find(
          (m) =>
            m.id == model.packageUmrahMealTypeMadinahId ||
            m.id == model.packageUmrahMealTypeMakkahId
        );
        if (_m)
          meals.push({
            ..._m,
            k:
              prices.find((el) => el.id == model.priceId).hotelMakkahName +
              " - " +
              prices.find((el) => el.id == model.priceId).hotelMadinahName,
          });
      });
    }
    setselectedMeal(meals);
    console.log(pack);
  }, [model.id]);

  return (
    <>
      <label>
        <Checkbox
          onChange={(v) => {
            _setmodel((prev) => ({
              ...prev,
              priceId: null,
              packageUmrahMealTypeMakkahId: null,
              packageUmrahMealTypeMadinahId: null,
              totalPrice: 0,
              onlyTicket: !prev.onlyTicket,

              withoutTicket: !prev.onlyTicket ? false : prev.withoutTicket,
              withoutVisa: !prev.onlyTicket ? false : prev.withoutVisa,
              visaOnly: !prev.onlyTicket ? false : prev.visaOnly,
            }));
          }}
          checked={
            model.onlyTicket
            //||
            // (model.prices &&
            //   model.prices.find((_el) => _el.id == p.id))
          }
        />
        Billet Seulement
      </label>
      <label>
        <Checkbox
          onChange={(v) => {
            _setmodel((prev) => ({
              ...prev,
              priceId: null,
              packageUmrahMealTypeMakkahId: null,
              packageUmrahMealTypeMadinahId: null,
              totalPrice: 0,
              visaOnly: !prev.visaOnly,

              withoutTicket: !prev.visaOnly ? false : prev.withoutTicket,
              withoutVisa: !prev.visaOnly ? false : prev.withoutVisa,
              ticketSystem: !prev.visaOnly ? false : prev.ticketSystem,
              onlyTicket: !prev.visaOnly ? false : prev.onlyTicket,
            }));
          }}
          checked={
            model.visaOnly
            //||
            // (model.prices &&
            //   model.prices.find((_el) => _el.id == p.id))
          }
        />
        Visa Seulement
      </label>

      <hr></hr>
      {data &&
        Object.keys(data).map((k, __i) => (
          <div
            style={{
              marginBottom: "30px",
              background: "#fff",
              borderRadius: "5px",

              boxShadow: "-11px 4px 26px -10px rgba(31,50,150,0.67)",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <table style={{ borderRadius: "5px !important" }}>
                {data[k]
                  .filter((p) => !p.isHidden)
                  .map((p, i) => {
                    return (
                      <tr
                        style={{
                          padding: "5px",
                          borderRadius: "5px !important",
                          background: i % 2 ? "rgb(70,103,209,0.2)" : "",
                        }}
                      >
                        <td
                          style={{
                            padding: "5px",
                          }}
                        >
                          <Checkbox
                            onChange={(v) => {
                              if (!multiple) {
                                setselectedMeal([]);
                                _setmodel((prev) => ({
                                  ...prev,
                                  priceId: p.id,
                                  totalPrice: p.price,
                                  packageUmrahMealTypeMakkahId: null,
                                  packageUmrahMealTypeMadinahId: null,
                                  onlyTicket: false,

                                  withoutTicket: data[k].find(
                                    (el) => el.id == prev.priceId
                                  )
                                    ? prev.withoutTicket
                                    : false,
                                  withoutVisa: data[k].find(
                                    (el) => el.id == prev.priceId
                                  )
                                    ? prev.withoutVisa
                                    : false,
                                }));
                              } else {
                                _setmodel((prev) => {
                                  let __prices = [...prev.prices];
                                  if (prev.prices.find((_el) => _el.id == p.id))
                                    __prices = __prices.filter(
                                      (_el) => _el.id != p.id
                                    );
                                  else __prices.push(p);
                                  return { ...prev, prices: __prices };
                                });
                              }
                            }}
                            checked={
                              model.priceId == p.id
                              //||
                              // (model.prices &&
                              //   model.prices.find((_el) => _el.id == p.id))
                            }
                          />
                          {
                            <Tag
                              color={
                                ["red", "green", "yellow"][
                                  roomTypes.findIndex(
                                    (__el) =>
                                      __el.label ==
                                      p.arrangementMakkahDesignation
                                  )
                                ]
                              }
                            >
                              {p.arrangementMakkahDesignation}
                            </Tag>
                          }
                        </td>
                        <td>
                          <img
                            alt=""
                            src="/makkah.png"
                            style={{ width: "40px" }}
                          />
                          {p.hotelMakkahName}
                        </td>
                        <td>
                          <img
                            alt=""
                            src="/madinah4.png"
                            style={{ width: "40px" }}
                          />
                          {p.hotelMadinahName}
                        </td>
                        <td>
                          <b style={{ color: "#232323", fontSize: "17px" }}>
                            {p.price}
                            <span
                              style={{
                                color: "#888",
                                fontSize: "11px",
                                fontWeight: "400",
                              }}
                            >
                              TND
                            </span>
                          </b>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
            {!multiple && (
              <div
                style={{
                  marginTop: "5px",
                  background: "#fff",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                {pack.hotels &&
                  pack.hotels
                    .filter(
                      (h) =>
                        h.name == data[k][0].hotelMadinahName ||
                        h.name == data[k][0].hotelMakkahName
                    )
                    .map((h) => {
                      if (h.packageUmrahMealTypes)
                        return h.packageUmrahMealTypes.map((m) => (
                          <div
                            onClick={() => {
                              let meals = [];
                              if (model.packageUmrahMealTypeMadinahId) {
                                pack.hotels
                                  .filter(
                                    (h) => h.name == data[k][0].hotelMadinahName
                                  )
                                  .map((_h) =>
                                    meals.push({
                                      ..._h.packageUmrahMealTypes.find(
                                        (el) =>
                                          el.id ===
                                          model.packageUmrahMealTypeMadinahId
                                      ),
                                      k,
                                    })
                                  );
                              }
                              if (model.packageUmrahMealTypeMakkahId) {
                                pack.hotels
                                  .filter(
                                    (h) => h.name == data[k][0].hotelMakkahName
                                  )
                                  .map((_h) =>
                                    meals.push({
                                      ..._h.packageUmrahMealTypes.find(
                                        (el) =>
                                          el.id ===
                                          model.packageUmrahMealTypeMakkahId
                                      ),
                                      k,
                                    })
                                  );
                              }
                              console.log(meals);

                              //   meals = meals.filter((el) => el.k == k);
                              if (meals.find((el) => el.id == m.id)) {
                                meals = meals.filter((el) => el.id !== m.id);
                              } else meals.push({ ...m, k });

                              if (!data[k].find((el) => el.id == model.priceId))
                                meals = [];
                              console.log("meals");
                              console.log(meals);
                              setselectedMeal(meals);
                              _setmodel((prev) => {
                                return {
                                  ...prev,
                                  packageUmrahMealTypeMakkahId: h.isMakkah
                                    ? meals.find(
                                        (el) => el.id == m.id && el.k == k
                                      )
                                      ? m.id
                                      : null
                                    : prev.packageUmrahMealTypeMakkahId,
                                  packageUmrahMealTypeMadinahId: !h.isMakkah
                                    ? meals.find(
                                        (el) => el.id == m.id && el.k == k
                                      )
                                      ? m.id
                                      : null
                                    : prev.packageUmrahMealTypeMadinahId,
                                  totalPrice:
                                    (data[k].find((el) => el.id == prev.priceId)
                                      ? data[k].find(
                                          (el) => el.id == prev.priceId
                                        ).price
                                      : 0) +
                                    meals.reduce((a, b) => a + b.totalPrice, 0),
                                };
                              });
                            }}
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "5px",
                              border:
                                selectedMeal.find(
                                  (el) => el.id === m.id && k === el.k
                                ) ||
                                (data[k].find((el) => el.id == model.priceId) &&
                                  (model.packageUmrahMealTypeMakkahId == m.id ||
                                    model.packageUmrahMealTypeMadinahId ==
                                      m.id))
                                  ? "2px solid rgb(70, 103, 209)"
                                  : "1px solid #ddd",
                              borderRadius: "5px",
                              margin: "2px",
                              cursor: model && "pointer",
                            }}
                          >
                            <b
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "25px",
                                  color: "rgb(70, 103, 209)",
                                  borderRadius: "50%",
                                  border: "1px solid #eee",
                                  width: "40px",
                                  height: "40px",
                                  textAlign: "center",
                                  display: "inline-block",
                                  marginRight: "8px",
                                  background: "rgb(70, 103, 209,0.2)",
                                }}
                              >
                                <MdOutlineFoodBank />
                              </span>
                              <span>
                                {m.designation}
                                <br></br>
                                <span
                                  style={{
                                    textTransform: "uppercase",
                                    color: "#888",
                                    fontWeight: "lighter",
                                  }}
                                >
                                  {h.name}
                                  <span style={{ fontSize: "0.7em" }}>
                                    {h.isMakkah ? "Makkah" : "Madinah"}
                                  </span>
                                </span>
                              </span>
                            </b>
                            <b
                              style={{
                                color: "#53af50",
                                fontSize: "18px",
                                margin: "3px",
                              }}
                            >
                              {m.totalPrice
                                ? format_number(m.totalPrice)
                                : format_number(
                                    h.duration * h.exchange * m.cost
                                  )}
                              <span
                                style={{
                                  color: "#888",
                                  fontSize: "11px",
                                  fontWeight: "400",
                                }}
                              >
                                TND
                              </span>
                            </b>
                          </div>
                        ));
                    })}
                <hr />
                <label>
                  <Checkbox
                    onChange={(v) => {
                      _setmodel((prev) => ({
                        ...prev,
                        withoutVisa: !prev.withoutVisa,
                        visaOnly: !prev.withoutVisa ? false : prev.visaOnly,
                        totalPrice:
                          prev.totalPrice - (!prev.withoutVisa ? visa : 0),
                      }));
                    }}
                    checked={
                      model.withoutVisa &&
                      data[k].find((el) => el.id == model.priceId)
                      //||
                      // (model.prices &&
                      //   model.prices.find((_el) => _el.id == p.id))
                    }
                  />
                  Sans Visa
                  {model.withoutVisa &&
                    data[k].find((el) => el.id == model.priceId) && (
                      <b
                        style={{
                          color: "#53af50",
                          fontSize: "18px",
                          margin: "3px",
                        }}
                      >
                        {format_number(model.totalPrice)}
                        <span
                          style={{
                            color: "#888",
                            fontSize: "11px",
                            fontWeight: "400",
                          }}
                        >
                          TND
                        </span>
                      </b>
                    )}
                </label>
                <label>
                  <Checkbox
                    onChange={(v) => {
                      _setmodel((prev) => ({
                        ...prev,
                        withoutTicket: !prev.withoutTicket,
                        onlyTicket: !prev.withoutTicket
                          ? false
                          : prev.onlyTicket,
                        ticketSystem: !prev.withoutTicket
                          ? false
                          : prev.ticketSystem,
                      }));
                    }}
                    checked={
                      model.withoutTicket &&
                      data[k].find((el) => el.id == model.priceId)
                    }
                  />
                  Sans Billet
                </label>{" "}
                <label>
                  <Checkbox
                    onChange={(v) => {
                      _setmodel((prev) => ({
                        ...prev,
                        ticketSystem: !prev.ticketSystem,
                        withoutTicket: !prev.ticketSystem
                          ? false
                          : prev.withoutTicket,
                        visaOnly: !prev.ticketSystem ? false : prev.visaOnly,
                      }));
                    }}
                    checked={
                      model.ticketSystem &&
                      data[k].find((el) => el.id == model.priceId)
                    }
                  />
                  Billet Systéme
                </label>
              </div>
            )}
          </div>
        ))}
    </>
  );
};
