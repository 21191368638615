export const ENDPOINTS = {
  Accounts: "Accounts",
  Attachment: "Attachment",
  Agency: "Agency",
  Auth: "Auth",
  Bank: "Bank",
  Check: "Check",
  Checkout: "Checkout",
  Client: "Client",
  Creditor: "Creditor",
  Currency: "Currency",
  Expense: "Expense",
  Package: "Package",
  PackageItem: "PackageItem",
  Provider: "Provider",
  Role: "Role",
  Sale: "Sale",
  Order: "Order",
  ServiceType: "ServiceType",
  Tag: "Tag",
  Ticket: "Ticket",
  Transaction: "Transaction",
  Employee: "Employee",
  Payment: "Payment",
  EmployeePosition: "EmployeePosition",
  AgentBookingFlight: "AgentBookingFlight",
  File: "file",
  Purchase: "Purchase",
  Ledger: "Ledger",
  Exchange: "Exchange",
  RecoveryPoint: "RecoveryPoint",
  PackageUmrah: "PackageUmrah",
  Airline: "Airline",
  Forcast: "ForcastUmrah",
  Config: "Config",
  ExportImportTY: "ExportImportTY",
  Group: "Group",
  Rooming: "Rooming",
  Disponibility: "Disponibility",
  Rawdha: "Rawdha",
  ForcastVo: "ForcastVo",
  Season: "Season",
};
