import moment from "moment";
import { Input, Message, SelectPicker } from "rsuite";

function AddEdit({ _setmodel, error, model }) {
  return (
    <>
      <label>Designation:</label>
      <Input
        onChange={(designation) => {
          _setmodel((prev) => {
            return { ...prev, designation };
          });
        }}
        value={model.designation}
      />

      <label>Begin Date</label>
      <Input
        type="date"
        value={
          typeof model.dateBegin == "string"
            ? model.dateBegin.substring(0, 10)
            : model.dateBegin
        }
        onChange={(dateBegin) => {
          _setmodel((prev) => {
            return { ...prev, dateBegin };
          });
        }}
      />

      <label>End Date</label>
      <Input
        type="date"
        value={
          typeof model.dateEnd == "string"
            ? model.dateEnd.substring(0, 10)
            : model.dateEnd
        }
        onChange={(dateEnd) => {
          _setmodel((prev) => {
            return { ...prev, dateEnd };
          });
        }}
      />

      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
