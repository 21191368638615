import ImageIcon from "@rsuite/icons/Image";
import React, { useEffect, useState } from "react";

import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Pagination,
  SelectPicker,
  Tag,
} from "rsuite";
import Swal from "sweetalert2";
import { APi } from "../../Api";
import { ENDPOINTS } from "../../Api/enpoints";
import { ConfigState } from "../../Atoms/configs.atom";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import { groupsAtoms } from "../../Atoms/groups.atoms";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import { BASE_URL } from "../../Config/api.config";
import GroupModel from "../../Models/GroupModel";
import AddEdit from "./AddEdit.component";
import Visas from "./Visas";
import WakilModel from "../../Models/wakilVisa/WakilModel";
import { seasonAtoms } from "../../Atoms/seasons.atom";
export default function Groups(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [openGroupDetails, setopenGroupDetails] = useState(false);
  const [groupId, setgroupId] = useState(0);
  const [data2, setdata2] = useRecoilState(groupsAtoms);
  const [totalCount, settotalCount] = useState(0);
  const [selectedVisa, setselectedVisa] = useState(0);
  const [pilgrims, setpilgrims] = useState([]);
  const seasons = useRecoilValue(seasonAtoms);

  const [filterModel, setfilterModel] = useState({
    q: "",
    page: 1,
    take: 50,
    date: null,
    seasonId: 0,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useState(new GroupModel());
  const [selected, setselected] = useState(0);
  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new GroupModel());
    setError("");
  };
  // useEffect(() => {

  // }, []);

  // API CALLS
  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });

    APi.createAPIEndpoint(APi.ENDPOINTS.Group, filterModel)
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });

    APi.createAPIEndpoint(APi.ENDPOINTS.Group, { page: 1, take: 1000, q: "" })
      .fetchAll()
      .then((res) => {
        setdata2(res.data.data);
      })
      .catch((e) => {});
  };
  const save = () => {
    let msg = "";
    if (msg) setError(msg);
    else {
      setstate((prev) => {
        return { ...prev, loading: true };
      });
      let m = { ...model };
      let season = seasons.find((s) => s.isCurrent);
      if (season) m.seasonId = season.id;
      if (model.id) {
        APi.createAPIEndpoint(APi.ENDPOINTS.Group)
          .update(model.id, m)
          .then((res) => {
            fetch();
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
            reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Élément a été bien modifié !",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((e) => {
            setError(e.Message);
            setstate((prev) => {
              return { ...prev, loading: false };
            });
          });
      } else {
        APi.createAPIEndpoint(APi.ENDPOINTS.Group)
          .create(m)
          .then((res) => {
            fetch();
            reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Element a été bien ajouté !",
              showConfirmButton: false,
              timer: 1500,
            });
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
          })
          .catch((e) => {
            setError(e.Message);
            setstate((prev) => {
              return { ...prev, loading: false };
            });
          });
      }
    }
  };

  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Group)
      .delete(id)
      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  const getBYId = (id) => {
    setError("");

    setmodel(data.find((el) => el.id == id));
  };
  // LIFE CYCLES
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  useEffect(() => {
    if (groupId) fetchByGroup();
  }, [groupId]);
  const columns = [
    {
      value: "name",
      name: "NOM",
      render: (v) => <b style={{ fontSize: "18px" }}>{v}</b>,
    },
    {
      value: "dateArrival",
      name: "DATE D'ARRIVEE",
      render: (v) => <b>{v ? v.substring(0, 10) : v}</b>,
    },
    {
      value: "total",
      name: "TOTAL",
      render: (v) => <b style={{ color: "#23233", fontSize: "20px" }}>{v}</b>,
    },
    {
      value: "sendedNumber",
      name: "EVOIYES VERS WAKIL",
      render: (v) => <b style={{ color: "orange", fontSize: "20px" }}> {v} </b>,
    },
    {
      value: "visasNumber",
      name: "VISAS",
      render: (v) => (
        <b style={{ color: "#4caf50", fontSize: "20px" }}> {v} </b>
      ),
    },

    {
      value: "id",
      name: "DETAILS",
      render: (v) => (
        <Button
          appearance="ghost"
          color="blue"
          onClick={() => {
            setopenGroupDetails(true);
            setgroupId(v);
          }}
        >
          Détails
        </Button>
      ),
    },
    {
      value: "id",
      name: "-",
      render: (v) => (
        <Button
          appearance="primary"
          color="orange"
          onClick={() => setselected(v)}
        >
          envoyer
        </Button>
      ),
    },
    {
      value: "id",
      name: "-",
      render: (v) => (
        <Button
          appearance="primary"
          color="green"
          onClick={() => setselectedVisa(v)}
        >
          Visas
        </Button>
      ),
    },
  ];
  const handleClose = () => {
    setpilgrims([]);
    setgroupId(0);
    setopenGroupDetails(false);
  };
  const fetchByGroup = () => {
    APi.createAPIEndpoint(ENDPOINTS.Order + "/Umrah", {
      page: 1,
      take: 10000,
      groupId,
    })
      .customGet()
      .then((res) =>
        setpilgrims(
          res.data.data
            .filter((el) => el)
            .map((el, i) => ({ ...el, index: i + 1 }))
        )
      );
  };
  return selected ? (
    <PilgrimsSend
      groupId={selected}
      setgroup={() => {
        setselected(0);
        localStorage.setItem("stop", true);
      }}
      group={data.find((el) => el.id == selected)}
    />
  ) : selectedVisa ? (
    <Visas
      groupId={selectedVisa}
      setgroup={() => {
        setselectedVisa(0);
        localStorage.setItem("stop1", true);
        fetch();
      }}
      group={data.find((el) => el.id == selectedVisa)}
    />
  ) : (
    <div>
      <Filter search={() => fetch()}>
        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Nom: </label>

          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Période: </label>
          <DatePicker
            placement="bottomStart"
            value={filterModel.date}
            onChange={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            onSelect={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            block
          />
        </Responsive>
        <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Season: </label>
          <SelectPicker
            data={[{ label: "Tout", value: 0 }].concat(
              seasons.map((c) => {
                return { label: c.designation, value: c.id };
              })
            )}
            block
            noSearch
            value={filterModel.seasonId}
            onSelect={(seasonId) => {
              setfilterModel((prev) => {
                return { ...prev, seasonId };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        // excelData={multiDataSet(data)}
        noExport
        size="md"
        save={save}
        AddComponent={
          <AddEdit error={error} model={model} _setmodel={setmodel} />
        }
      />{" "}
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
        <Modal
          size={"lg"}
          overflow={false}
          style={{
            maxHeight: "calc(100vh - 50px)",
            overflow: "auto",
            maxWidth: "100vw",
          }}
          open={openGroupDetails}
          onClose={() => {
            handleClose();
          }}
        >
          <Modal.Header>
            <Modal.Title>Groupe Détails</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                maxHeight: "calc(100vh - 240px)",
                overflow: "auto",
                maxWidth: "100vw",
              }}
            >
              <Grid columns={columns2} rows={pilgrims} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                handleClose();
              }}
              appearance="subtle"
            >
              Annuler
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

const PilgrimsSend = ({ groupId, group, setgroup }) => {
  const [pilgrims, setpilgrims] = useState([]);
  const [conf, setconf] = useRecoilState(ConfigState);
  const [stop, setstop] = useState(false);
  const [index, setindex] = useState(0);
  const [loading, setloading] = useState(false);
  const [checkeds, setcheckeds] = useState([]);

  useEffect(() => {
    localStorage.setItem("stop", true);
    setcheckeds([]);
  }, []);
  const send_recursive = async (i, els) => {
    setindex(i);
    let _st = localStorage.getItem("stop");
    if (els[i]) {
      if (_st == "false") {
        console.log(conf);
        let elemt_to_send = new WakilModel();
        let _el = { ...els[i] };

        elemt_to_send = {
          ...elemt_to_send,
          ..._el.customer,
          login: conf.login,
          password: conf.password,
          phoneNumber: conf.phone,
          email: conf.email,
          groupName: group.name,
          dateArrival: group.dateArrival.substring(0, 10),
        };
        console.log(_el.customer);
        let _c = { ..._el.customer };
        elemt_to_send.firstName = _c.firstName;
        elemt_to_send.lastName = _c.lastName;
        elemt_to_send.firstNameAr = _c.firstNameAr;
        elemt_to_send.lastNameAr = _c.lastNameAr;
        elemt_to_send.passportExpirationDate =
          _c.expirationPassportDate.substring(0, 10);
        //  .substring(0, 10).split("-").reverse().join("-")
        elemt_to_send.passportDeliveryDate = _c.deliveryPassportDate.substring(
          0,
          10
        );
        elemt_to_send.birthDate = _c.birthDate.substring(0, 10);
        //  .substring(0, 10).split("-").reverse().join("-")
        elemt_to_send.fatherName = _c.fatherName;
        elemt_to_send.maritalStatusCode = _c.status || 999;
        elemt_to_send.birthCountry = _c.birthCountry || "216";
        elemt_to_send.nationality = _c.nationality || "216";
        elemt_to_send.addressCountry = _c.city;
        elemt_to_send.city = _c.addressCountry || "216";
        elemt_to_send.passportDeliveryCountry =
          _c.passportDeliveryCountry || "216";
        elemt_to_send.passportDeliveryCity = _c.passportDeliveryCity;

        console.log(elemt_to_send);
        let _res = await axios.post(
          "http://localhost:8733/SetCustomerNusuk",
          elemt_to_send
        );
        if (_res.status == 200 && _res.data.Erreur == "Success") {
          _el.codeMoufa = _res.data.codeMoufa || "Success";
          _el.errorMessage = "";
        } else {
          _el.errorMessage = "erreur envoie";
        }
        setpilgrims((prev) => {
          let da = [...prev];
          let __i = da.findIndex((___el) => ___el.id == _el.id);
          da[__i] = { ..._el };
          return da;
        });
        delete _el.client;
        delete _el.disponibility;
        delete _el.packageUmrah;
        delete _el.price;
        APi.createAPIEndpoint(ENDPOINTS.Order + "/Umrah")
          .update2(_el)
          .then((res) => {
            if (i < els.length - 1) {
              send_recursive(i + 1, els);
            } else setloading(false);
          })
          .catch((e) => {
            console.log("index: ", i);
            if (i < els.length - 1) {
              send_recursive(i + 1, els);
            } else setloading(false);
          });
      }
    } else {
      if (i < els.length - 1) {
        send_recursive(i + 1, els);
      } else setloading(false);
    }
  };

  const fetch = () => {
    APi.createAPIEndpoint(ENDPOINTS.Order + "/Umrah", {
      page: 1,
      take: 10000,
      groupId,
    })
      .customGet()
      .then((res) =>
        setpilgrims(
          res.data.data
          // .filter(
          //   (el) =>
          //     el.errorMessage !=
          //       "هناك طلب فعال لحامل هذا الجواز. لا يمكن اضافة آخر" &&
          //     el.errorMessage != "Success"
          // )
        )
      );
  };
  useEffect(() => {
    fetch();
    console.log(group);
  }, [groupId]);
  const send = async () => {
    localStorage.setItem("stop", false);
    setloading(true);
    // let els = pilgrims.map((p) => ({
    //   ...p.,
    //   login: conf.login,passport
    //   password: conf.password,
    //   phoneNumber: group.commonPhone,
    //   email: group.commonEmail,
    //   arrivalDate: group.dateArrival
    //     ? group.dateArrival.substring(0, 10).split("-").reverse().join("-")
    //     : "",
    //   passportDeliveryDate: p.passportDeliveryDate
    //     ? p.passportDeliveryDate.split("-")[0].length == 2
    //       ? p.passportDeliveryDate.split("-").reverse().join("-")
    //       : p.passportDeliveryDate
    //     : "",
    //   refNumber: group.refNumber,
    // }));
    console.log(checkeds);
    let els = pilgrims
      .filter(
        (p1) =>
          p1.confirmationStatus == 3 &&
          !p1.withoutVisa &&
          checkeds.find((_p) => _p == p1.id)
      )
      .map((el, i) => ({ ...el, index: i + 1 }));

    console.log(els);

    await send_recursive(0, els);
  };
  const columns22 = [
    {
      value: "codeMoufa",
      value2: "errorMessage",
      render: (v, v2) => (
        <span
          style={{
            content: "",
            width: "10px",
            height: "50px",
            background: v || v2 == "Success" ? "green" : v2 ? "red" : "orange",
            display: "block",
          }}
        ></span>
      ),
    },
    {
      value: "index",
      name: "-",
      render: (v) => (
        <b
          style={{
            fontSize: "20px",
          }}
        >
          {v}
        </b>
      ),
    },
    {
      value: "customer",
      value2: "id",
      name: " Nom",
      render: (v, id) => (
        <b style={{ color: "green", display: "flex", alignItems: "center" }}>
          <Checkbox
            onChange={(v) => {
              if (checkeds.find((el) => el == id))
                setcheckeds((prev) => prev.filter((l) => l != id));
              else setcheckeds((prev) => [...prev, id]);
            }}
            checked={checkeds.find((el) => el == id) != null}
          ></Checkbox>
          <a style={{ display: "flex", alignItems: "center" }}>
            {v.picture ? (
              <img
                alt=""
                src={
                  BASE_URL +
                  "Uploads/CustomerPictures/" +
                  v.picture +
                  "?" +
                  Date.now()
                }
                width="43"
                height="43"
                style={{
                  border: "1px solid #eee",
                  borderRadius: "50%",
                  boxShadow: "-11px -5px 20px -13px rgba(0,0,0,0.75)",
                }}
              />
            ) : (
              <div
                style={{
                  fontSize: "15px",
                  border: "1px dashed #888",
                  borderRadius: "50%",
                  width: "43px",
                  height: "43px",
                  textAlign: "center",
                  lineHeight: "42px",
                }}
              >
                <ImageIcon color="#3598ff"></ImageIcon>
              </div>
            )}
            <div style={{ padding: "0 5px" }}>
              {" "}
              {v.firstName + "  " + v.lastName}
              {"  "}
              <i
                style={{
                  color: "#999",
                  fontSize: "14px",
                  fontWeight: "lighter",
                }}
              >
                {v ? v.passportNumber : ""}
              </i>
              <br></br>{" "}
              <i style={{ color: "#444" }}>
                {v.firstNameAr + "  " + v.lastNameAr}
              </i>
            </div>
          </a>
        </b>
      ),
    },

    {
      value: "codeMoufa",
      value2: "errorMessage",
      value3: "visaCode",

      name: "Resultat",
      render: (v, v2, visaCode) => (
        <span
          style={{
            color: visaCode
              ? "white"
              : v || v2 == "Success"
              ? "green"
              : v2
              ? "red"
              : "",
            padding: "5px 10px",
            background: visaCode ? "blue" : "",
            borderRadius: "5px",
          }}
        >
          {visaCode
            ? visaCode
            : v
            ? v == "Success"
              ? "Envoyé vers wakil"
              : ""
            : v2
            ? "erreur envoie"
            : "-"}
        </span>
      ),
    },
  ];

  return (
    <div>
      <div style={{ textAlign: "right", padding: "10px" }}>
        <Button appearance="primary" color="" onClick={setgroup}>
          retour
        </Button>{" "}
        <Button appearance="primary" color="orange" onClick={send}>
          Envoyer
        </Button>{" "}
        <Button
          appearance="primary"
          color="red"
          onClick={() => {
            setstop(true);
            localStorage.setItem("stop", true);
            setloading(false);
          }}
        >
          arréter
        </Button>
      </div>{" "}
      {loading && (
        <div
          style={{
            display: "flex ",
            alignItems: "center",
            border: "1px solid #eee",
            padding: "10px",
            background: "#fff",
            maxWidth: "800px",
            margin: "0 auto",
            marginBottom: "10px",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            En cours :
            <b style={{ color: "green" }}>
              {pilgrims.filter(
                (p1) =>
                  p1.confirmationStatus == 3 &&
                  !p1.withoutVisa &&
                  checkeds.find((_p) => _p == p1.id)
              )[index].customer.name ||
                pilgrims.filter(
                  (p1) =>
                    p1.confirmationStatus == 3 &&
                    !p1.withoutVisa &&
                    checkeds.find((_p) => _p == p1.id)
                )[index].customer.firsName +
                  "  " +
                  pilgrims.filter(
                    (p1) =>
                      p1.confirmationStatus == 3 &&
                      !p1.withoutVisa &&
                      checkeds.find((_p) => _p == p1.id)
                  )[index].customer.lastName}{" "}
              {/* <br></br>
              <i style={{ color: "#444" }}>
                {pilgrims[index].firsNameAr + "  " + pilgrims[index].lastNameAr}
              </i> */}
            </b>
          </div>
          <img alt="...loading" src="/svg-loaders/spinning-circles.svg"></img>
        </div>
      )}
      <div
        onClick={(e) =>
          setcheckeds((prev) =>
            prev.length ? [] : pilgrims.map((el) => el.id)
          )
        }
        style={{
          display: "flex",
          alignItems: "center",

          padding: "8px",
          borderRadius: "4px",
          background: "#fff",
        }}
      >
        <Checkbox checked={checkeds.length == pilgrims.length}></Checkbox>{" "}
        Sélectionner Tout
      </div>
      <Grid columns={columns22} rows={pilgrims} />
    </div>
  );
};
const columns2 = [
  {
    value: "codeMoufa",
    value2: "errorMessage",
    render: (v, v2) => (
      <span
        style={{
          content: "",
          width: "10px",
          height: "50px",
          background: v || v2 == "Success" ? "green" : v2 ? "red" : "orange",
          display: "block",
        }}
      ></span>
    ),
  },
  {
    value: "index",
    name: "-",
    render: (v) => (
      <b
        style={{
          fontSize: "20px",
        }}
      >
        {v}
      </b>
    ),
  },
  {
    value: "customer",
    name: "Client B2C",
    render: (v) => (
      <a style={{ display: "flex", alignItems: "center" }}>
        {v.picture ? (
          <img
            alt=""
            src={
              BASE_URL +
              "Uploads/CustomerPictures/" +
              v.picture +
              "?" +
              Date.now()
            }
            width="43"
            height="43"
            style={{
              border: "1px solid #eee",
              borderRadius: "50%",
              boxShadow: "-11px -5px 20px -13px rgba(0,0,0,0.75)",
            }}
          />
        ) : (
          <div
            style={{
              fontSize: "15px",
              border: "1px dashed #888",
              borderRadius: "50%",
              width: "43px",
              height: "43px",
              textAlign: "center",
              lineHeight: "42px",
            }}
          >
            <ImageIcon color="#3598ff"></ImageIcon>
          </div>
        )}
        <div style={{ padding: "0 5px" }}>
          {" "}
          {v.firstName + "  " + v.lastName}
          {"  "}
          <i
            style={{
              color: "#999",
              fontSize: "14px",
              fontWeight: "lighter",
            }}
          >
            {v ? v.passportNumber : ""}
          </i>
          <br></br>{" "}
          <i style={{ color: "#444" }}>{v.firstNameAr + "  " + v.lastNameAr}</i>
        </div>
      </a>
    ),
  },

  {
    value: "codeMoufa",
    value2: "errorMessage",
    value3: "visaCode",

    name: "Resultat",
    render: (v, v2, visaCode) => (
      <span
        style={{
          color: visaCode
            ? "white"
            : v || v2 == "Success"
            ? "green"
            : v2
            ? "red"
            : "",
          padding: "5px 10px",
          background: visaCode ? "blue" : "",
          borderRadius: "5px",
        }}
      >
        {visaCode
          ? visaCode
          : v
          ? v == "Success"
            ? "Envoyé vers wakil"
            : ""
          : v2
          ? "erreur envoie"
          : "-"}
      </span>
    ),
  },
];
